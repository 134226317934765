import React from 'react';
import { graphql } from 'gatsby';
import { SanityHiltonPost } from 'graphql-types';
import { PAGINATION_PAGE_SIZE } from '../../../components/Blog/blog-index-page-template';
import { HiltonEducationIndexPageTemplate } from '../../../components/HiltonEducation/hilton-education-index-page-template';

interface HiltonPageProps {
  data: {
    posts: {
      edges: {
        node: SanityHiltonPost;
      }[];
      totalCount: number;
    };
  };
}

const HiltonPage = ({ data }: HiltonPageProps) => {
  const posts = data?.posts.edges.map(edge => edge.node);
  const numOfPages = Math.ceil(data?.posts.totalCount / PAGINATION_PAGE_SIZE);

  return (
    <HiltonEducationIndexPageTemplate
      posts={posts}
      totalNumberOfPages={numOfPages}
      pathPrefix="hilton/education"
      showHeadliner
    />
  );
};

export const query = graphql`
  fragment HiltonPostCardInfo on SanityHiltonPost {
    _id
    themes {
      title
    }
    id
    title
    slug {
      current
    }
    image {
      _type
      ...SanityImageFragment
    }
    title
    author {
      ...HiltonPostAuthor
    }
    content: _rawContent
  }

  query HiltonIndexQuery {
    posts: allSanityHiltonPost(
      limit: 10
      filter: { slug: { current: { ne: null } } }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...HiltonPostCardInfo
        }
      }
      totalCount
    }
  }
`;

export default HiltonPage;
